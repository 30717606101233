:root {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
input,
input[type='text'],
input[type='password'],
input[type='submit'] textarea,
button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  color: inherit;
}
